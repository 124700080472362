window.domainFetchPromise = new Promise((resolve, reject) => {
    const host = location.host.includes('dev.') || location.host.includes('localhost') ? 'https://lpa.dev.weltrade.com' : 'https://lpa.wtactions.com ';

    fetch(host,
        {
            method: 'POST',
            body: JSON.stringify([
                {
                    "action": "tools.getStaticUrl",
                    "data": {}
                }
            ])
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return response.json();
        })
        .then(data => {
            window.EXTERNAL_LINKS = data[0].data;
            resolve();
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            reject(error);
        });
});